import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import dayjs from 'dayjs'

import CoverImage from '../components/cover-image'
import styles from './basic-content.module.css'
import Carousel from '../components/carousel'
import { useIsMobile } from '../hooks/screen-size'
import { classes } from '../util/components'
import SEO from '../components/seo'

const carouselSlides = (carousel, title, onPlay, onPause) =>
  (carousel || []).map(i => {
    const imageSrc =
      i.image && i.image.childImageSharp ? i.image.childImageSharp.fluid : null

    return (
      <CoverImage
        className={styles.coverImage}
        videoUrl={i.youtube}
        image={imageSrc}
        title={title}
        onTrailerPlay={onPlay}
        onTrailerPause={onPause}
      />
    )
  })

export const BasicContentTemplate = ({ page, hash }) => {
  const { carousel, subtitle, title, date } = page.frontmatter
  const { collection } = page.fields
  const [isSlidePaused, setIsSlidePaused] = useState(false)
  const isMobile = useIsMobile()
  const onVideoPlay = useRef()
  const onVideoPause = useRef()


  onVideoPlay.current = () => {
    setIsSlidePaused(true)
  }

  onVideoPause.current = () => {
    setIsSlidePaused(false)
  }

  const slides = carouselSlides(
    carousel,
    title,
    onVideoPlay.current,
    onVideoPause.current
  )

  return (
    <>
     <SEO 
      title={page.frontmatter.meta_title} 
      description={page.frontmatter.meta_description}
    />
    <div
      className={classes(
        'container',
        'flex',
        'flex-col',
        'items-center',
        styles[collection]
      )}
    >
      <div className={styles.header}>
        <div className={styles.subtitle}>{subtitle}</div>
        <h1 className={styles.title}>
          {title}
          {date && (
            <small className={styles.date}>
              Posted: <time dateTime={date}>{dayjs(date).format('D MMM YYYY')}</time>
            </small>
          )}
        </h1>
      </div>
      {slides.length ? (
        <div className={styles.carouselWrapper}>
          <Carousel
            slides={slides}
            previewSlides={isMobile}
            isPaused={isSlidePaused}
          />
        </div>
      ) : null}
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: page.html }}
      />
    </div>
    </>
  )
} 

const BasicContentPage = ({ data, location }) => {
  return <BasicContentTemplate hash={location.hash} {...data} />
}

BasicContentPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default BasicContentPage

export const pageQuery = graphql`
  query BasicContentById($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        carousel {
          image {
            childImageSharp {
              fluid(maxWidth: 1280, maxHeight: 720, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          videoUrl
        }
        title
        meta_title
        meta_description
        subtitle
        date
      }
      fields {
        collection
      }
      html
    }
  }
`
